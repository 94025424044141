#landing {
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, minmax(0, 1fr));
    height: 100vh;

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      //border: 1px solid black;
    }

    .link {
      width: 100%;
      height: 100%;
      position: absolute;

      a, a:hover, a:active, a:visited {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        font-family: 'helvetica_lt_sultracompressed';
        font-size: 5.2vw;
        text-shadow: 0.35rem 0.35rem 0.35rem #000000b0;
        opacity: 0.85;
        
        h2 {
          margin: 0;
          font-weight: normal;
        }
      }      
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .btn-container {
    position: absolute;
    bottom: 30.5vh;
    display: flex;
    justify-content: center;
    width: 100%;
    pointer-events: none;

    img {
      width: 25.8rem;
      margin-left: 0.4vw;

      @media (max-width: 767px) {
        width: calc(100% - 2rem)
      }
    }
  }

  .logo {
    position: absolute;
    bottom: 1.5vw;
    left: 1.7vw;
    width: 10.7vw;
    width: 13rem;
    height: auto;

    @media (max-width: 767px) {
      width: 7rem;
    }
  }
}
