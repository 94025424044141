
@font-face {
  font-family: 'helvetica_lt_sultracompressed';
  src: url('../../public/fonts/helveticaltstd-ultracomp-webfont.woff2') format('woff2'),
       url('../../public/fonts/helveticaltstd-ultracomp-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'helvetica_neue_lt_std77BdCn';
  src: url('../../public/fonts/helveticaneueltstd-bdcn-webfont.woff2') format('woff2'),
       url('../../public/fonts/helveticaneueltstd-bdcn-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'helvetica_neue_lt_std87HvCn';
  src: url('../../public/fonts/helveticaneueltstd-hvcn-webfont.woff2') format('woff2'),
       url('../../public/fonts/helveticaneueltstd-hvcn-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'helvetica_neue_lt_std67MdCn';
  src: url('../../public/fonts/helveticaneueltstd-mdcn-webfont.woff2') format('woff2'),
       url('../../public/fonts/helveticaneueltstd-mdcn-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'scala_sans_otblack';
  src: url('../../public/fonts/scalasansot-black-webfont.woff2') format('woff2'),
       url('../../public/fonts/scalasansot-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'zapf_dingbatsregular';
  src: url('../../public/fonts/zapfdingbats-webfont.woff2') format('woff2'),
       url('../../public/fonts/zapfdingbats-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

body {
  margin: 0;
  font-family: 'helvetica_neue_lt_std67MdCn';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.35rem;
  // font-size: 1.124vw;
  background-color: #000;
  color: #fff;
  -ms-touch-action: none;
  touch-action: none;
}

button {
  cursor: pointer;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#animationRoot {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  main {
    flex: 1;
    position: relative;
    overflow: auto;
  }
}

a, button {
  -webkit-tap-highlight-color: transparent;
}