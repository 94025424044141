.goals-page {
  background-color: #fff;
  color: #000;
  white-space: pre-wrap;

  &#peopleGoals {
    section .row > div {
      // max-width: 19.727rem;
      min-width: 11.28rem;
      flex: 1;

      @media (max-width: 768px) {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &#environmentGoals {
    section .row > div {
      min-width: 11.28rem;
      flex: 1;

      @media (max-width: 768px) {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  
  &#communityGoals {
    section .row > div {
      max-width: 19.66rem;
      min-width: 16.08rem;
      flex: 1;

      @media (max-width: 1040px) {
        max-width: unset;
      }

      @media (max-width: 768px) {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  section {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding: 2.16rem 4.67rem;

    @media (max-width: 768px) {
      padding: 2rem;
    }

    h1 {
      margin: 0 0 0.96rem -0.25rem;
      font-size: 11.76rem;
      text-transform: uppercase;
      font-family: 'helvetica_lt_sultracompressed';
      font-weight: normal;
      color: #f15a31;

      @media (max-width: 860px) {
        font-size: 21.88vw;
      }

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      > div {
        padding: 0 1.2rem;
        border-left: 1px dotted #000;
        height: 39.66rem;

        @media (max-width: 1919px) {
          margin-bottom: 3rem;
          height: unset;
        }

        // &:first-child {
        //   border-left: 1px dotted #000;
        // }

        &:last-child {
          border-right: none;
          // padding-right: 0;
        }

        .tiles {
          display: flex;
          gap: 1.05rem;

          img {
            width: 4.68rem;
            height: auto;
          }
        }
  
        h2 {
          margin: 1.56rem 0 0 0;
          font-size: 1.104rem;
          font-weight: normal;
          line-height: 1.224rem;
          letter-spacing: 0.019rem;
          width: 101.3%;
        }
  
        p {
          margin-top: 0.72rem;
          margin-bottom: 0;
          font-size: 0.84rem;
          font-weight: bold;
          line-height: 1.08rem;
          letter-spacing: 0.0084rem;
        }

        ul {
          width: 100%;
          font-size: 0.84rem;
          margin-top: 0.6rem;
          margin-bottom: 0.575rem;
          padding-left: 0.6rem;
          line-height: 1.092rem;
          list-style: none;
          color: #747e85;

          li {
            position: relative;
            margin-top: 0.36rem;
            // letter-spacing: -0.017vw;

            &::before {
              content: "•";
              color: #747e85;
              font-size: 0.7rem;
              position: absolute;
              left: -0.6rem;
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }

        a {
          display: block;
          font-size: 0.948rem;
          line-height: 1.08rem;
          text-decoration: none;
          color: #f15a31;
          letter-spacing: -0.004rem;

          b {
            font-family: 'helvetica_neue_lt_std87HvCn';
          }
        }
      }
    }
  }
}