.case-study {
  background-color: #fff;

  .container {
    display: flex;
    justify-content: center;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    padding: 0 4.67rem;
  
    @media (max-width: 1545px) {
      flex-direction: column-reverse;
      justify-content: flex-end;
      height: auto;
      min-height: 100%;
    }

    @media (max-width: 768px) {
      padding: 0 2rem;
    }

    .left {
      padding: 4.45rem 0 0 0;
      color: #5b6770;
      white-space: pre-wrap;

      @media (max-width: 1545px) {
        padding: 4.45rem 0;
        white-space: unset;
      }

      @media (max-width: 768px) {
        padding: 2rem 0;
        padding-bottom: calc(2rem - 1.151rem);
      }

      h1 {
        margin-top: 0;
        margin-bottom: 0.6rem;
        font-family: 'helvetica_lt_sultracompressed';
        font-size: 3.48rem;
        color: #f15a31;
        //white-space: pre-wrap;
        text-transform: uppercase;
        letter-spacing: 0.097rem;
        line-height: 3.48rem;
        /*span {
          display: block;
          margin-bottom: 1.9vw;
          font-family: 'helvetica_neue_lt_std77BdCn';
          text-transform: capitalize;
          font-size: 0.944vw;
          letter-spacing: normal;
          line-height: normal;
          color: #000;
        }*/
      }

      p {
        margin-top: 0;
        margin-bottom: 1.15rem;
        // letter-spacing: 0.029vw;
        line-height: 1.835rem;
      }

      ul {
        padding-left: 1.2rem;
        margin-bottom: 1.1rem;

        li {
          margin-bottom: 1.151rem;
          line-height: 1.836rem;
        }

        &.gt7 {
          li {
            margin-bottom: 0.12rem;
            line-height: 1.7218rem;
          }
        }
      }
    }

    .right {
      position: relative;
      margin: 4.7rem 0 0 4.67rem;

      @media (max-width: 1545px) {
        margin: 4.7rem 0 0 0;
      }

      @media (max-width: 768px) {
        margin: 4.7rem 0 0 0;
      }

      > img {
        width: 57vw;
        max-width: 68.4rem;
        //height: 53.4rem;
        object-fit: cover;

        @media (max-width: 1545px) {
          width: 100%;
          max-width: unset;
          height: auto;
        }
      }

      .content {
        position: absolute;
        top: 0;
        right: 0;
        width: 15.5rem;
        min-width: 152px;
        background-color: rgba(91, 103, 112, 0.9);
        font-family: 'scala_sans_otblack';
        color: #fff;

        @media (max-width: 1920px) {
          width: 12.9vw;
        }

        @media (max-width: 1545px) {
          width: 20.2vw;
        }

        @media (max-width: 1200px) {
          width: 19.4vw;
        }

        @media (max-width: 925px) {
          width: 18.9vw;
        }

        @media (max-width: 820px) {
          width: 18.4vw;
        }
        
        @media (max-width: 768px) {
          position: relative;
          width: 100%;
        }
        
        > * {
          white-space: pre-wrap;
        }

        .tiles {
          position: absolute;
          top: -2.64rem;
          display: flex;
          justify-content: center;
          gap: 0.85rem;
          width: 100%;
          z-index: 1;

          @media (max-width: 768px) {
            justify-content: flex-end;
          }

          img {
            opacity: 1;
            width: 5.2rem;
            height: 5.2rem;

            &:last-child {
              @media (max-width: 768px) {
                margin-right: 2.15rem
              }
            }

            // @media (max-width: 454px) {
            //   width: 18.4vw;
            //   height: 18.4vw;
            // }
          }

          &.gt2 {
            justify-content: flex-end;

            @media (max-width: 454px) {
              justify-content: center;
            }

            img {
              &:last-child {
                margin-right: 2.15rem;

                @media (max-width: 454px) {
                  margin-right: 0;
                }
              }
            }
          }
        }

        ul {
          display: none;
          margin: 0;
          font-size: 0.9rem;
          text-transform: uppercase;
          list-style: none;
          padding: 3rem 1.5rem 1.5rem;

          @media (max-width: 768px) {
            display: block;
          }

          li {
            margin-top: 1rem;
            line-height: 1.75rem;
          }
        }

        & > img {
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}