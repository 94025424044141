.category-page {

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 4.58rem);
    object-fit: cover;
    z-index: -1;

    @media (max-width: 768px) {
      height: 100%;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    height: 100%;
    padding: 0 4.55rem;
    margin: 0 auto;
    gap: 1rem;

    @media (max-width: 1545px) {
      flex-direction: column;
      justify-content: space-around;
      height: auto;
      min-height: 100%;
      padding: 0 2rem;
    }

    @media (max-width: 768px) {
      gap: 0;
    }
  }

  .left {
    // position: absolute;
    // top: 13.95rem;
    // left: 4.32rem;

    @media (max-width: 1545px) {
      width: 100%;
      margin-bottom: 2rem;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 0.62rem;
      font-family: 'helvetica_lt_sultracompressed';
      font-size: 11.76rem;
      text-transform: uppercase;
      letter-spacing: 0.008rem;
      text-shadow: 0.35rem 0.35rem 0.35rem #000000b0;
      font-weight: normal;

      @media (max-width: 1545px) {
        text-align: center;
      }

      @media (max-width: 768px) {
        font-size: 23.1vw;
        text-shadow: 0.67vw 0.67vw 0.67vw #000000b0;
      }
    }

    .tiles {
      display: flex;
      gap: 1.07rem;
      flex-wrap: wrap;
      max-width: 37.3rem;
      margin-left: 0.29rem;

      @media (max-width: 1545px) {
        justify-content: center;
        margin: 0 auto;
      }

      img {
        width: 5.305rem;
        height: 5.305rem;
        z-index: 0;

        @media (max-width: 454px) {
          flex: 1;
          height: auto;
        }
      }
    }
  }

  .right {
    display: inline-block;
    // position: absolute;
    // top: 6.48rem;
    // right: 4.55rem;
    //margin-bottom: 7.4rem;
    padding: 0 2.89rem;
    width: 36.6rem;
    min-height: 42.45rem;
    background-color: #5B6770;
    opacity: 0.85;
    margin-bottom: 7.5rem;

    @media (max-width: 1545px) {
      margin-bottom: 2rem;
      min-height: unset;
      width: auto;
      max-width: 36.6rem;
    }

    @media (max-width: 768px) {
      padding: 0 5vw;
    }

    :nth-child(2) {
      margin-top: 0;
    }

    > * {
      font-size: 2.105rem;
      line-height: 2.645rem;
      letter-spacing: 0.031rem;
      white-space: pre-wrap;

      @media (max-width: 768px) {
        white-space: unset;
        font-size: 4.38vw;
        line-height: 5.51vw;
      }
    }

    h2 {
      font-family: "helvetica_neue_lt_std77BdCn";
      font-size: 2.69rem;
      text-align: center;
      margin-top: 3.96rem;
      margin-bottom: 1.68rem;
      line-height: 2.76rem;

      @media (max-width: 1545px) {
        margin-top: 2.08rem;
      }

      @media (max-width: 768px) {
        font-size: 5.6vw;
        line-height: 5.72vw;
        margin-top: 4.4vw;
        margin-bottom: 3.5vw;
      }
    }
  }
}